import { LogLevel } from "@azure/msal-browser";

const b2cTenant = process.env["REACT_APP_B2C_TENANT"];

export const b2cPolicies = {
  names: {
    signUpSignIn: process.env["REACT_APP_B2C_POLICY"],
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${b2cTenant}.b2clogin.com/${b2cTenant}.onmicrosoft.com/${process.env["REACT_APP_B2C_POLICY"]}`,
    },
  },
  authorityDomain: `${b2cTenant}.b2clogin.com`,
};

export const msalConfig = {
  auth: {
    clientId: `${process.env["REACT_APP_B2C_APP_CLIENT_ID"]}`, // This is the ONLY mandatory field that you need to supply.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
    knownAuthorities: [b2cPolicies.authorityDomain],
    scope: `https://${b2cTenant}.onmicrosoft.com/${process.env["REACT_APP_B2C_APP_CLIENT_ID"]}/read`,
    redirectUri: `${process.env["REACT_APP_B2C_APP_REDIRECT_URI"]}`, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: `/`, // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

export const protectedResources = {
  prosoft: {
    endpoint: `https://${b2cTenant}.onmicrosoft.com/86101551-200a-4650-92c7-b35e4c323882`,
    scopes: {
      read: [
        `https://${b2cTenant}.onmicrosoft.com/f9f243b1-2387-4713-8351-8b5c210aa6bf/read`,
      ],
    },
  },
};

export const loginRequest = {
  scopes: [
    "offline_access",
    "openid",
    ...protectedResources.prosoft.scopes.read,
  ],
};

export const forgottenRequest = {
  scopes: [],
  authority: `https://${b2cTenant}.b2clogin.com/${b2cTenant}.onmicrosoft.com/${process.env["REACT_APP_B2C_POLICY_FORGOTTENPASSWORD"]}`,
};

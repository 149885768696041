import React from "react";
import "./DownloadProgressbar.css";
import DownloadProgressbar from "./DownloadProgressbar";

const ProgressBarContainer = (props) => {
  return (
    <div className="progressbar">
      {props.responses.map((r) => (
        <DownloadProgressbar
          key={r.id}
          response={r}
          onFileDownloaded={props.onFileDownloaded}
        />
      ))}
    </div>
  );
};

export default ProgressBarContainer;

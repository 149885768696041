import React, { useEffect, useState } from "react";
import "./Popup.css";
import { postApiData } from "../../services/ApiDataService";
import Loading from "../loading/Loading";
import { useMsal } from "@azure/msal-react";

const Popup = (props) => {
  const [checked, setChecked] = useState("");
  const [loading, setLoading] = useState(false);
  const { instance } = useMsal();

  const handleChecked = (item) => {
    setChecked((value) => (item.path == value ? "" : item.path));
  };

  const handleSubmit = async () => {
    props.onClick();
    try {
      await instance.initialize();
      const token = await instance.acquireTokenSilent(
        instance.getActiveAccount()
      );
      props.handleResponse(postApiData(token.idToken, checked), checked);
      setLoading(false);
    } catch {
      props.errorHandle();
    }
  };

  useEffect(() => {
    if (props.item.files.length === 1) {
      setChecked(props.item.files[0].path);
    }
  }, [props.item.files]);

  return (
    <>
      <div className="popup-overlay" onClick={props.onClick}>
        <div className="popup" onClick={(e) => e.stopPropagation()}>
          <div>
            <div className="popup-header">
              <span className="popup-title">Stiahnuť aplikáciu</span>
              <img
                className="popup-dismiss"
                src="/images/Dismiss.png"
                alt="close"
                onClick={props.onClick}
              />
            </div>
            <div className="popup-text-container">
              <span className="popup-text">{props.item.name}</span>
              {props.item.files.map((item, i) => (
                <div className="popup-versions" key={i}>
                  <input
                    type="checkbox"
                    className="popup-checkbox"
                    checked={item.path == checked}
                    onChange={() => handleChecked(item)}
                  />
                  <span className="popup-input-text">
                    Dátum publikovania verzie:{" "}
                    {new Date(item.createdOn).toLocaleDateString()} -{" "}
                    <span className="popup-text-bold">
                      {item.version} {item.branch} verzia
                    </span>
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="popup-button-download">
            <button
              onClick={handleSubmit}
              className="button-style"
              disabled={checked == ""}>
              Stiahnuť
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Popup;

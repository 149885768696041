import React from "react";
import "./ErrorMessage.css";

const ErrorMessage = () => {
  return (
    <div className="error-container">
      <div className="error-message">
        <span className="error-text">Vyskytla sa chyba</span>
      </div>
    </div>
  );
};

export default ErrorMessage;

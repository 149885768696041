import { getData, postData } from "./ApiService";

export const getApiData = (token) => {
  return getData(`${process.env["REACT_APP_URL"]}/api/GetVisibleFiles`, token);
};

export const postApiData = (accessToken, path) => {
  return postData(
    `${process.env["REACT_APP_URL"]}/api/GetFile`,
    accessToken,
    path
  );
};

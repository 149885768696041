import "./DownloadProgressbar.css";

import React, { useEffect, useState } from "react";

const DownloadProgressbar = (props) => {
  const [progress, setProgress] = useState(0);
  const [fileSize, setFileSize] = useState(0);
  const [inProgress, setInProgress] = useState(false);

  const responseHandle = async (responsePromise) => {
    try {
      const response = await responsePromise.response;

      const reader = response.body.getReader();

      const contentLength = +response.headers.get("Content-Length");

      setFileSize(Math.round((contentLength / 1000000) * 10) / 10);

      let receivedLength = 0;
      let chunks = [];
      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          break;
        }

        chunks.push(value);
        receivedLength += value.length;

        setProgress((receivedLength / contentLength) * 100);
      }

      const contentType = response.headers.get("content-type");

      if (contentType) {
        const fileUrl = window.URL.createObjectURL(new Blob(chunks));

        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", props.response.fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);

        props.onFileDownloaded(responsePromise.id);

        return;
      }

      return response.json();
    } catch {
      return;
    }
  };

  useEffect(() => {
    if (!inProgress) {
      setInProgress(true);
      responseHandle(props.response);
    }
  }, []);

  const containerStyles = {
    height: "5px",
    width: "95%",
    backgroundColor: "#e0e0de",
    borderRadius: "5px",
    margin: "0px 15px",
  };

  const fillerStyle = {
    height: "5px",
    width: `${progress}%`,
    backgroundColor: "#0464FA",
    borderRadius: "inherit",
    textAlign: "right",
  };

  return (
    <div style={{ width: "95%", margin: "12px 0px 10px 0px" }}>
      <div className="label">
        <div>
          <span className="download-title download-text">
            {props.response.fileName}{" "}
          </span>
          {fileSize != 0 && (
            <span className="file-size download-text">({fileSize} MB)</span>
          )}
        </div>
        <span className="file-size">{Math.floor(progress)}%</span>
      </div>
      <div style={containerStyles}>
        <div style={fillerStyle}></div>
      </div>
    </div>
  );
};

export default DownloadProgressbar;

import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import "../App.css";
import { protectedResources } from "../authConfig";
import AppTileBig from "../components/appTile/AppTileBig";
import AppTileSmall from "../components/appTile/AppTileSmall";
import EmptyMessage from "../components/emptyMessage/EmptyMessage";
import ErrorMessage from "../components/errorMessage/ErrorMessage";
import Header from "../components/header/Header";
import Loading from "../components/loading/Loading";
import Popup from "../components/popup/Popup";
import Search from "../components/search/Search";
import { getApiData } from "../services/ApiDataService";
import ProgressBarContainer from "../components/downloadProgressbar/ProgressBarContainer";

const HomePage = () => {
  const [loading, setLoading] = useState(true);
  const [apiData, setApiData] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [bigTiles, setBigTiles] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState({});
  const [error, setError] = useState(false);
  const [responses, setResponses] = useState([]);
  const { instance, accounts } = useMsal();

  const appVerison = process.env["REACT_APP_VERSION"];

  useEffect(() => {
    setFilteredItems(apiData);
  }, [apiData]);

  useEffect(() => {
    if (accounts && accounts.length > 0) getData();
  }, [accounts]);

  const handleError = () => {
    setError(true);

    setTimeout(() => {
      setError(false);
    }, 3000);
  };

  const getData = async () => {
    setLoading(true);
    try {
      await instance.initialize();
      const token = await instance.acquireTokenSilent({
        account: instance.getActiveAccount(),
        scopes: protectedResources.prosoft.scopes.read,
      });
      let data = await getApiData(token.idToken);
      setApiData(data);
      if (data.length > 0) {
        setFilteredItems(apiData);
      }
    } catch (error) {
      handleError();
    }
    setLoading(false);
  };

  const handleToggle = () => {
    setBigTiles((prevValue) => !prevValue);
  };

  const togglePopup = () => {
    setPopupOpen((value) => !value);
  };

  const handleResponse = (response, path) => {
    const pathSegments = path.split("/");

    const fileName = pathSegments[pathSegments.length - 1];
    setResponses((prev) => [
      {
        id: (prev.sort((a, b) => a.id - b.id).slice(-1)[0]?.id ?? 0) + 1,
        response: response,
        fileName: fileName,
      },
      ...prev,
    ]);
  };

  const handelDeletePromise = (responseId) => {
    setResponses((prev) => [...prev.filter((r) => r.id != responseId)]);
  };

  const handleSearch = (query) => {
    const filtered = apiData.filter((item) => {
      const mainBranchFiles = item.files.filter(
        (file) => file.branch.toLowerCase() === "main"
      );
      const foundByName = item.name.toLowerCase().includes(query.toLowerCase());
      const foundByVersion = mainBranchFiles.some((file) =>
        file.version.toLowerCase().includes(query.toLowerCase())
      );
      return foundByName || foundByVersion;
    });

    setFilteredItems(filtered);
  };

  if (loading) return <Loading />;
  return (
    <div className="app">
      <Header user={instance.getActiveAccount()?.name} />
      {error ? <ErrorMessage /> : <></>}
      <div className="main">
        <div
          className="flex-row-center"
          style={{ justifyContent: "space-between" }}>
          <div className="flex-row-center">
            <h2 className="title">Prehľad aplikácií</h2>
            <img className="img-16-16" src="/images/Find.png" alt="find" />
            <label className="switch">
              <input
                type="checkbox"
                checked={!bigTiles}
                onChange={handleToggle}
              />
              <span className="slider"></span>
            </label>
            <img className="img-16-16" src="/images/List.png" alt="find" />
          </div>
          <Search onSearch={handleSearch} />
        </div>
        {filteredItems.length === 0 ? (
          <EmptyMessage />
        ) : (
          <>
            <div className="tile-grid">
              {filteredItems.map((item, index) => {
                const mainFile = item.files.find(
                  (file) => file.branch === "Main"
                );
                const version = mainFile ? mainFile.version : "";
                const Component = bigTiles ? AppTileSmall : AppTileBig;
                return (
                  <Component
                    key={index}
                    title={item.name}
                    version={version}
                    text={item.description}
                    date={mainFile.createdOn}
                    onClick={() => {
                      setSelectedApplication(item);
                      togglePopup();
                    }}
                  />
                );
              })}
            </div>
          </>
        )}
        {popupOpen ? (
          <Popup
            item={selectedApplication}
            onClick={togglePopup}
            errorHandle={handleError}
            handleResponse={handleResponse}
          />
        ) : (
          <></>
        )}
      </div>
      <ProgressBarContainer
        responses={responses}
        onFileDownloaded={handelDeletePromise}
      />
      <div className="app-verison">{appVerison}</div>
    </div>
  );
};

export default HomePage;

import { EventType } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { useCallback, useEffect } from "react";
import CacheBuster from "react-cache-buster";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { forgottenRequest, loginRequest } from "./authConfig";
import Loading from "./components/loading/Loading";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";

const App = () => {
  const { accounts, instance } = useMsal();
  const isProduction = process.env.NODE_ENV === "production";

  useEffect(() => {
    const acc = getUserAccount();
    if (acc) {
      instance.setActiveAccount(acc);
    }
  }, [accounts]);

  useEffect(() => {
    const callbackId = instance.addEventCallback(handleForgotPassword);
    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  const handleForgotPassword = (message) => {
    if (
      message.eventType == EventType.LOGIN_FAILURE &&
      message.error.errorMessage.includes("AADB2C90118")
    ) {
      try {
        instance.loginRedirect(forgottenRequest);
      } catch (error) {}
    } else if (
      message.eventType == EventType.LOGIN_SUCCESS &&
      message.payload.idTokenClaims.tfp.toLocaleUpperCase() ==
        process.env[
          "REACT_APP_B2C_POLICY_FORGOTTENPASSWORD"
        ].toLocaleUpperCase()
    ) {
      instance.loginRedirect(loginRequest);
    }
  };

  const getUserAccount = useCallback(() => {
    if (accounts) {
      return accounts.find(
        (a) =>
          a.idTokenClaims.tfp.toLocaleUpperCase() ==
          process.env["REACT_APP_B2C_POLICY"].toLocaleUpperCase()
      );
    }
  }, [accounts]);

  return (
    <CacheBuster
      currentVersion={process.env["REACT_APP_VERSION"]}
      isEnabled={isProduction}
      isVerboseMode={false}
      loadingComponent={<Loading />}
      metaFileDirectory={"."}
    >
      <>
        <AuthenticatedTemplate>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/home" element={<HomePage />} />
            </Routes>
          </BrowserRouter>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <BrowserRouter>
            <Routes>
              <Route path="*" element={<LoginPage />} />
            </Routes>
          </BrowserRouter>
        </UnauthenticatedTemplate>
      </>
    </CacheBuster>
  );
};

export default App;

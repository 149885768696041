import React from "react";
import "./EmptyMessage.css";

const EmptyMessage = () => {
  return (
    <div className="empty-container">
      <img
        className="empty-image"
        src="/images/EmptyMessage.png"
        alt="emptyMessage"
      />
      <span className="empty-text">
        Nenašli sme žiadne aplikácie, pre ktoré by ste mali oprávnenia. Prosím,
        kontaktujte administrátora systému.
      </span>
    </div>
  );
};

export default EmptyMessage;

import React from "react";
import "./AppTileBig.css";

const AppTileBig = (props) => {
  return (
    <div className="app-tile-big" onClick={props.onClick}>
      <div className="flex-column-center">
        <div className="flex-column-center" style={{ gap: "10px" }}>
          <span className="tile-header">{props.title}</span>
          <div className="version-date">
            <span className="version">{props.version}</span>
            <span>{new Date(props.date).toLocaleDateString()}</span>
          </div>
        </div>
      </div>
      <span className="main-text">{props.text}</span>
      <div className="padding-bottom">
        <button className="button-style">Stiahnuť</button>
      </div>
    </div>
  );
};

export default AppTileBig;

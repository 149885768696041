import React from "react";
import "./Search.css";

const Search = (props) => {
  const handleChage = (e) => {
    props.onSearch(e.target.value);
  };

  return (
    <div className="search-container">
      <input className="search" placeholder="Search" onChange={handleChage} />
      <button className="search-button">
        <img src="/images/Search.png" alt="search" />
      </button>
    </div>
  );
};

export default Search;

import React from "react";
import "./AppTileSmall.css";

const AppTileSmall = (props) => {
  return (
    <div className="app-tile-small" onClick={props.onClick}>
      <div className="flex-row-center">
        <div
          className="circle"
          style={{ position: "absolute", filter: "blur(2px)" }}
        />
        <div className="circle" style={{ zIndex: "0" }}>
          <img className="person" src="/images/Person.png" alt="person" />
        </div>
        <div className="tile-text">
          <span className="small-tile-title">{props.title}</span>
          <span className="tile-version">
            {props.version}
            {" -"} {new Date(props.date).toLocaleDateString()}
          </span>
        </div>
      </div>
      <img className="tile-arrow" src="/images/Arrow (4).png" alt="arrow" />
    </div>
  );
};

export default AppTileSmall;

export async function getData(endpoint, accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(endpoint, options).then((response) => {
    if (!response.ok) {
      throw new Error();
    }
    return response.json();
  });
}

export async function postData(endpoint, accessToken, path) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const dataToSend = {
    name: path,
  };

  const response = await fetch(endpoint, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(dataToSend),
    responseType: "blob",
  });

  if (!response.ok) {
    throw new Error("");
  }

  return response;
}

import React, { useEffect, useRef, useState } from "react";
import "./Header.css";
import { useMsal } from "@azure/msal-react";

const Header = (props) => {
  const [showLogout, setShowLogout] = useState(false);
  const { instance } = useMsal();
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowLogout(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div className="header">
      <img src="/images/Logo.png" alt="logo"></img>
      <div
        onClick={() => setShowLogout(!showLogout)}
        className="user-dropdown"
        ref={dropdownRef}>
        <div className="flex-row-center">
          <span className="user">{props.user}</span>
          <div className="arrow-down" />
        </div>
        {showLogout && (
          <div
            className="options"
            onClick={() =>
              instance.logoutRedirect({ postLogoutRedirectUri: "/" })
            }>
            <img
              className="sign-out-img"
              src="/images/Sign Out.png"
              alt="signOut"
            />
            <span className="sign-out-text">Odhlásiť sa</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;

import { useMsal } from "@azure/msal-react";
import React from "react";
import { forgottenRequest, loginRequest } from "../authConfig";
import "./LoginPage.css";

const LoginPage = () => {
  const { instance } = useMsal();

  return (
    <div className="page">
      <div className="image">
        <img className="logo" src="/images/Logo.png" alt="logo"></img>
      </div>
      <div className="flex-column-center">
        <div className="login-main">
          <span className="header-text">
            Ponúkame spoľahlivé riešenia pre zdravotnícke zariadenia
          </span>
          <span className="subtext">
            Máme stabilný tím vzdelaných a kompetentných pracovníkov s
            dlhoročnými skúsenosťami v oblasti zdravotníckej informatiky.
          </span>
        </div>
        <div className="login-button-container">
          <div>
            <button
              className="button-style"
              style={{
                height: "68px",
                width: "238px",
                justifyContent: "space-between",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => instance.loginRedirect(loginRequest)}
            >
              <span className="login-button-text">Prihlásiť sa</span>
              <img
                className="login-button-img"
                src="/images/Arrow.png"
                alt="arrow"
              ></img>
            </button>
          </div>
          <div className="forgot-password">
            <span onClick={() => instance.loginRedirect(forgottenRequest)}>
              Zabudli ste heslo?
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
